import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import {AuthenticationService} from '../../../../../app/shared/services/authentication.service';
import {Qrcode2FAComponent} from '../../../../../app/shared/components/qrcode-2FA/qrcode-2FA.component';
import {MatDialog} from '@angular/material/dialog';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'Můj profil',
      description: 'Přehled aktivit a přiřazených služeb',
      colorClass: 'text-teal',
      route: '/uzivatele/profil/me'
    },
    // {
    //   id: '2',
    //   icon: 'mat:list_alt',
    //   label: 'Přiřazené úkoly',
    //   description: 'Úkoly ke splnění',
    //   colorClass: 'text-amber',
    //   route: '/apps/scrumboard'
    // },
  ];

  trackById = trackById;

  constructor(private cd: ChangeDetectorRef,
              public authService: AuthenticationService,
              private dialog: MatDialog,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) { }

  ngOnInit() {
  }

  close() {
    this.popoverRef.close();
  }
  logout() {
    this.popoverRef.close();
    this.authService.logout();
  }

  open2FAQRCode(): void {
    const dialogRef = this.dialog.open(Qrcode2FAComponent, {width: '300px'});
    dialogRef.afterClosed().subscribe(async result => {
    });
  }
}
